import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"
import OfferCard from "../components/OfferCard"

import Container from "react-bootstrap/Container"

const OfferPage = ({ data: { offers } }) => {
  return (
    <Layout>
      <SEO
        title="Акції на Інтернет і Телебачення"
        description="Акційні ціна на Інтернет і Телебачення від нового Телекому."
        image=""
        url="/promo/"
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              {/* <li className="breadcrumb-item">
                <Link to="/">Допомога</Link>
              </li> */}
              <li className="breadcrumb-item active" aria-current="page">
                Акції
              </li>
            </ol>
          </nav>
          <h1 className="gradient-text">Акції на Інтернет</h1>
          <div className="hero-slogan mb-5">
            Перелік дійсних акцій Інтернет-провайдера Новий Телеком
          </div>
        </Container>
      </div>
      <Container className="py-5">
        {offers.nodes.map(offer => (
          <OfferCard
            key={offer.id}
            offerImage={offer.offerImage.fluid}
            offerTitle={offer.title}
            offerSlogan={offer.slogan.childMarkdownRemark.html}
            offerDescription={offer.description?.childMarkdownRemark?.html}
            isNeedToApply={offer.isNeedToApply}
          />
          // <div
          //   className="offer-slogan"
          //   dangerouslySetInnerHTML={{
          //     __html: offer.description.childMarkdownRemark.html,
          //   }}
          // ></div>
        ))}
      </Container>
    </Layout>
  )
}

export default OfferPage

export const query = graphql`
  {
    offers: allContentfulPromoOffer(
      filter: { node_locale: { eq: "uk" } }
      sort: { fields: id, order: DESC }
    ) {
      nodes {
        id
        title
        isNeedToApply
        description {
          childMarkdownRemark {
            html
          }
        }
        slogan {
          childMarkdownRemark {
            html
          }
        }
        offerImage {
          fluid(maxWidth: 960, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
