import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"

// import { TiGift } from "react-icons/ti"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"

import ModalOffer from "../components/global/ModalOffers"

const OfferCard = ({
  offerImage,
  offerTitle,
  offerSlogan,
  pageTitle,
  offerDescription,
  isNeedToApply,
  offerUrl,
}) => {
  const offerImageGradient = [
    offerImage,
    `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.87), rgba(15, 1, 94, 0.545))`,
  ].reverse()
  return (
    <Col className="promooffer" lg={12} sm={12}>
      <BackgroundImage
        className="my-5"
        Tag="div"
        fluid={offerImageGradient}
        alt="bg"
      >
        <Col
          className="offer-wrapper"
          lg={{ span: 8, offset: 2 }}
          md={12}
          sm={12}
        >
          <div className="offer-content">
            <div className="h2 offer-header">{offerTitle}</div>
            <div
              className="offer-slogan"
              dangerouslySetInnerHTML={{
                __html: offerSlogan,
              }}
            ></div>
            {!isNeedToApply ? (
              ``
            ) : (
              <ModalOffer
                pageTitle={pageTitle}
                offerTitle={offerTitle}
                offerImage={offerImage}
                pageSlug="/promo/"
              />
              // <Button
              //   as={Link}
              //   to="/promo/"
              //   className="offer-button mr-3"
              //   variant="light"
              // >
              //   <span className="btn-icon-bg">
              //     <TiGift className="btn-icon" />
              //   </span>
              //   Отримати бонус
              // </Button>
            )}
            {offerUrl ? (
              <Button
                as={Link}
                to="/promo/"
                className="offer-button mr-3"
                variant="light"
              >
                {/* <span className="btn-icon-bg">
                  <TiGift className="btn-icon" />
                </span> */}
                Хочеш дізнатися більше?
              </Button>
            ) : (
              ``
            )}
          </div>
        </Col>
      </BackgroundImage>
      {/* <div
        className="offer-slogan"
        dangerouslySetInnerHTML={{
          __html: offerDescription,
        }}
      ></div> */}
    </Col>
  )
}

OfferCard.defaultProps = {
  offerImage: ``,
  offerTitle: `Offer Title`,
  offerSlogan: `Offer slogan text for to rows`,
  offerDescription: `Offer description`,
  isNeedToApply: false,
  offerUrl: false,
  pageTitle: `Акції`,
}

export default OfferCard
