import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import { TiGift } from "react-icons/ti"
// import Badge from "react-bootstrap/Badge"
// import { RiShoppingCartLine } from "react-icons/ri"

import FormConnect from "../global/FormConnect"
// import ProductCard from "../shop/ProductCard"

const ModalOffer = ({ props, offerImage, offerTitle, pageSlug, pageTitle }) => {
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)

  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulAsset(id: { eq: "dcf9e483-b82e-5df6-9c6c-e050baf5d3a3" }) {
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Button
              onClick={handleToggle}
              variant="light"
              className="offer-button"
            >
              <span className="btn-icon-bg">
                <TiGift className="btn-icon" />
              </span>
              Отримати бонус
            </Button>

            <Modal
              {...props}
              show={show}
              onHide={() => setShow(false)}
              aria-labelledby="cartModal"
              className="clearfix"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contactModal">
                  Введіть своє ім'я та номер телефону
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <p>
                    Щоб бонус було нараховано, будь ласка, зв’яжіться з нами.
                  </p>
                  <Row>
                    <Col lg={7} md={12}>
                      <FormConnect
                        pageSlug={`${pageSlug}#offer`}
                        pageTitle={pageTitle}
                        offerTitle={offerTitle}
                        formType="offer"
                      />
                    </Col>
                    <Col lg={5} md={12}>
                      <div className="shop-modal-product-name text-center">
                        Акція: {offerTitle}
                      </div>
                      <Img
                        className="shop-modal-product-image"
                        fluid={offerImage}
                        alt={offerTitle}
                      />
                      {/* <Badge variant="light">{productPrice}</Badge> */}
                      {/* <ProductCard
                        productTitle={productTitle}
                        productPrice={productPrice}
                        offerImage={offerImage}
                      /> */}
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal>
          </>
        )
      }}
    />
  )
}

export default ModalOffer
